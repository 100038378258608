export const surveyJSON = {
  locale: "es",
  title: "TEST: “¿Reconoces tu valor y confías REALMENTE en ti misma?”",
  pages: [
    {
      name: "1ra pági",
      elements: [
        {
          type: "radiogroup",
          name: "question1",
          title:
            "Sé muy bien qué es lo que me hace sentir bien, lo que me gusta y lo hago frecuentemente como parte de mi vida.",
          isRequired: true,
          choices: [
            {
              value: "3",
              text: "Muy pocas veces",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "0",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question2",
          title: "Pospongo tomar decisiones por miedo a equivocarme.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question3",
          title: "Pierdo la motivación y el interés fácilmente.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question4",
          title:
            "Paso demasiado tiempo pensando en lo que he dicho o hecho mal.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question5",
          title: "Cuando me preguntan ¿En qué soy buena? No sé qué responder.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question6",
          title:
            "Tengo tendencia a magnificar mis errores y minimizar mis logros.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question7",
          title:
            "Siento que considero demasiado la opinión de los demás para tomar decisiones en mi vida.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question8",
          title: "Busco y aprovecho las oportunidades que se me presentan.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text:
                "Tengo claro cuales son mis talentos y mi experiencia y confío en ellos al momento de tomar decisiones o cuando se presenta una buena oportunidad.",
            },
            {
              value: "2",
              text:
                "Dejo pasar buenas oportunidades. Las dudas y la incertidumbre hacen que sea difícil decidir.",
            },
            {
              value: "3",
              text:
                "Analizo demasiado los pros y los contras de la situación y al final me siento abrumada. Me quedo paralizada y sin actuar. Siento que “todavía no estoy lista”.",
            },
          ],
        },
      ],
      title: "TEST: “¿Reconoces tu valor y confías REALMENTE en ti misma?”",
      description: "Página 1 de 3, 24 preguntas en total.",
    },
    {
      name: "page2",
      elements: [
        {
          type: "radiogroup",
          name: "question12",
          title: "Soy muy crítica conmigo misma, más que con los demás.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question9",
          title: "Me enfoco en todo lo que podría ir mal.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question11",
          title:
            "Me cuesta trabajo decir “NO” cuando me piden algo, incluso cuando no estoy de acuerdo, algo no me gusta o no quiero hacerlo.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question10",
          title:
            "Mis conversaciones interiores son regularmente positivas y evalúo constructivamente los resultados de mis acciones.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text:
                "Cuando me equivoco sé que es normal y me digo que la siguiente vez será mejor.",
            },
            {
              value: "2",
              text:
                "En ocasiones me siento bastante motivada, pero algunos errores detonan la voz auto-crítica y es difícil detenerla. Me hago sentir muy mal.\u2028",
            },
            {
              value: "3",
              text:
                "Siendo honesta, soy muy pesimista. Normalmente me sorprendo pensando en todo lo malo que puede pasar. Esto consume mucha energía.",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question13",
          title:
            "Se me hace difícil ponerle límites a los demás, me faltan las palabras, no sé cómo hacerlo.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question14",
          title:
            "Me pongo a la defensiva o me siento fatal cuando siento que me están criticando.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question15",
          title:
            "Tengo miedo a hacer el ridículo, a hacer o decir algo que ponga en evidencia que “no sé lo suficiente”.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question16",
          title:
            "Tengo confianza en mí misma para tomar decisiones y emprender acciones.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text:
                "Sé que aunque no lo sepa todo en este momento confío en que soy capaz de aprender lo necesario para lograr lo que quiero.",
            },
            {
              value: "2",
              text:
                "Desconfío y dudo al empezar con algo, porque algunas veces dudo de mis habilidades.",
            },
            {
              value: "3",
              text:
                "Dudo mucho y desconfío de mis habilidades y capacidades, por eso me da miedo emprender la acción.",
            },
          ],
        },
      ],
      title: {
        default: "TEST: “¿Reconoces tu valor y confías REALMENTE en ti misma?”",
        es: "TEST: “¿Reconoces tu valor y confías realmente en ti misma?”",
      },
      description: "Página 2 de 3, 24 preguntas en total.",
    },
    {
      name: "page3",
      elements: [
        {
          type: "radiogroup",
          name: "question17",
          title:
            "Mis amigos, mi pareja y la gente cercana a mí me apoyan para conseguir lo que me propongo",
          isRequired: true,
          choices: [
            {
              value: "0",
              text:
                "¡Cierto! cuando les comparto que tengo una nueva meta me apoyan en todo e incluso me sugieren ideas para que se más sencillo conseguirlo.",
            },
            {
              value: "2",
              text:
                "No siempre, por que las personas cercanas a mí me sugieren hacer actividades que a veces van en contra de lo que quiero.",
            },
            {
              value: "3",
              text:
                "Las personas que me rodean hacen comentarios o ideas que me desmotivan o sugieren que lo que quiero no es importante o que es difícil de lograr.",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question18",
          title:
            "Tomo decisiones basándome en lo que será mejor para los demás y no en lo que yo quiero, muchas veces ni siquiera considero lo que yo quiero. \n",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question19",
          title:
            "Evito compartir mi opinión, ideas o sentimientos cuando estamos en grupo, por miedo a que me critiquen.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question20",
          title:
            "Soy perfeccionista. Me gusta tenerlo todo bajo control y me siento insegura cuando no es así.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question21",
          title:
            'Me sorprendo pensando que “No sé en qué soy buena y no sé qué es lo que quiero."',
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question22",
          title: "Tiendo a pensar negativamente sobre mí misma.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question23",
          title:
            "No sé qué hacer con mi vida, siento que algo me falta. Hay varias cosas que me gustaría cambiar.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "Nunca",
            },
            {
              value: "2",
              text: "Algunas veces",
            },
            {
              value: "3",
              text: "Frecuentemente",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question24",
          title:
            "Sé claramente lo que quiero en la vida y sé lo que necesito hacer para conseguirlo.",
          isRequired: true,
          choices: [
            {
              value: "0",
              text:
                "Definitivamente sé lo que quiero, ya estoy en ello y me mantengo motivada.",
            },
            {
              value: "2",
              text:
                "Tengo una idea de lo que me gustaría, aunque a veces no sé por dónde empezar o me falta motivación.",
            },
            {
              value: "3",
              text:
                "La verdad es que ando medio extraviada, tengo muchas dudas y no sé por dónde empezar.",
            },
          ],
        },
      ],
      title: {
        default: "TEST: “¿Reconoces tu valor y confías REALMENTE en ti misma?”",
        es: "TEST: “¿Reconoces tu valor y confías realmente en ti misma?”",
      },
      description: "Página 3 de 3, 24 preguntas en total.",
    },
    {
      name: "Surveyor Info",
      elements: [
        {
          type: "text",
          name: "email",
          visible: false,
          title: {
            es: "Email",
          },
          inputType: "email",
          placeHolder: {
            es: "Your email address",
          },
        },
        {
          type: "text",
          name: "name",
          visible: false,
          title: {
            es: "Nombre",
          },
        },
      ],
      visible: false,
    },
  ],
}
