import React from "react"
import { navigate } from "gatsby"
import * as SurveyJS from "survey-react"
import "survey-react/survey.css"
import { surveyJSON } from "../utils/surveyJSON"
//import ImageCover from "./assets/pexels-dominika-roseclay-1036841.jpg"
import ImageCover from "./assets/pexels-pixabay-434337.jpg"
import Navigation from "../components/navigation"

import Article from "../components/article"
import Section from "../components/section"
import BulletItem from "../components/bullet-item"

import PRT from "./pasos-resultados-test"

import { BsCheckCircle } from "react-icons/Bs"

import "./survey.css"
import Footer from "../components/footer"

const Survey = () => {
  const [isSurveyTaken, setIsSurveyTaken] = React.useState(false)
  const getLocalStorageData = () => ({
    storedName: localStorage.getItem("S_U_NAME"),
    storedEmail: localStorage.getItem("S_U_EMAIL"),
  })
  React.useEffect(() => {
    const { storedEmail, storedName } = getLocalStorageData()
    if (!storedEmail || !storedName) {
      navigate("/test-reconoces-tu-valor-y-confias-realmente-en-ti-misma")
    }
  }, [])
  const sendDataToServer = survey => {
    // console.log("The results are:" + JSON.stringify(survey.data))
    const { storedEmail, storedName } = getLocalStorageData()
    const surveyData = { ...survey.data, name: storedName, email: storedEmail }
    const { name, email, ...rest } = surveyData

    let score = 0
    Object.values(rest).forEach(answerScore => {
      const answer = parseInt(answerScore, 10)
      // console.log(answerScore, answer)
      score += answer
    })

    const lead_type = score >= 30 ? "BAJA" : score <= 12 ? "BUENA" : "REGULAR"

    // console.log(
    //   survey.data,
    //   "Score results",
    //   name,
    //   email,
    //   score,
    //   lead_type,
    //   rest
    // )

    const payload = {
      name,
      email,
      score,
      lead_type,
    }
    survey.data = surveyData

    sendDataToMailchimp(payload)
    survey.sendResult("f9566e31-b5f3-4e4a-a4d4-291c244880a9")
    // navigate("/pasos-resultados-test")
    // todo handle errors
    localStorage.clear()
    setIsSurveyTaken(true)
  }

  const sendDataToMailchimp = payload => {
    const url = `/ivone-get.php?name=${payload.name}&email=${payload.email}&score=${payload.score}&lead_type=${payload.lead_type}`
    postData(url)
  }
  // Example POST method implementation:
  const postData = async (url = "") => {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    return response
  }

  if (isSurveyTaken) return <PRT />

  return (
    <div>
      <Navigation active={-1} />

      <Section
        className="playfair font-size-verylarge"
        color="white"
        bgImage={ImageCover}
        overlay="full"
        //overlayColor="rgba(68, 68, 68,.4)"
        overlayColor="rgba(116, 194, 200,.6)"
      >
        <Article
          layout="narrow"
          title="TEST: “¿Reconoces tu valor y confías REALMENTE en ti misma?”"
          titleClassName="tenor white font-size-verylarge lineheight-large"
        ></Article>
      </Section>

      <Section color="white">
        <Article>
          {/*   <div className="tenor center blue1 font-size-verylarge lineheight-verylarge">
        TEST <br/> “¿Reconoces tu valor y confías realmente en ti misma?”
        </div>
        <br/> */}
          <div className="tenor center turkis-blue1 font-size-verylarge lineheight-verylarge">
            ¡Bienvenida!
          </div>
          <div className="center">
            Que bueno que decidiste regalarte estos minutos para conocerte
            mejor.
            <br />
            <br />
          </div>
          <div className="center blue1">
            Éste es solo el principio de una transformación super potente que
            estas a punto de experimentar.
          </div>
          <br />
          <div className="tenor left blue1 font-size-large lineheight-small">
            <br />
            Instrucciones:{" "}
          </div>
          <br />
          <BulletItem icon={<BsCheckCircle />}>
            {" "}
            Para cada afirmación marca la opción con la que te identifiques más.
          </BulletItem>
          <BulletItem icon={<BsCheckCircle />}>
            {" "}
            En caso de que no estés segura: ¡Marca la casilla que más se acerque
            a lo que <strong>sientes</strong>!{" "}
          </BulletItem>
          <BulletItem icon={<BsCheckCircle />}>
            {" "}
            Sé muy honesta. Aceptar las cosas tal y como son es el primer paso
            para empezar a transformarlas.{" "}
          </BulletItem>
          <br />
          <div className="tenor center blue1 font-size-large lineheight-verylarge">
            No te cuento más.
          </div>
          <div className="tenor center turkis-blue1 font-size-large lineheight-verylarge">
            ¡Empieza ya!
          </div>
          <br />
          <div className="center">- 24 preguntas -</div>
        </Article>
      </Section>
      <Section color="white">
        <Article layout="narrow survey-article">
          <SurveyJS.Survey json={surveyJSON} onComplete={sendDataToServer} />
        </Article>
      </Section>
      <Footer />
    </div>
  )
}

export default Survey
